<template>
  <Layout tituloPagina="Hotspot | Fichas">
    <div class="row">
      <div class="col-lg-4">
        <cmp-secciones seccion="fichas"></cmp-secciones>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-sm-8 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarFichas()
          "
          :datoini="dato"
          placeholder="ID/PIN"
        ></filtrador>
      </div>
      <div class="offset-lg-3 col-lg-3 col-sm-4 col-3 text-right">
        <router-link class="btn btn-success" :to="{ name: 'generarFichasHotspot' }">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-ticket"></i>
            Generar fichas
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-ticket"></i>
          </span>
        </router-link>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-3 col-md-6 mb-3">
        <div class="input-group">
          <span class="input-group-text">
            Hotspot
          </span>
          <select
            class="form-select"
            v-model="id_hotspot"
            @change="refrescarFichas()"
            style="min-width: 80px"
          >
            <option :value="null">Todos</option>
            <option
              v-for="hotspot in hotspots"
              :key="hotspot.id"
              :value="hotspot.id"
            >
              {{hotspot.nombre}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-3" v-show="usuarioActEsAdmin">
        <div class="input-group">
          <span class="input-group-text">
            Vendedor
          </span>
          <select
            class="form-select"
            v-model="id_vendedor"
            @change="refrescarFichas()"
          >
            <option :value="null">Todos</option>
            <option
              v-for="vendedor in vendedores"
              :key="vendedor.id"
              :value="vendedor.id"
            >
              {{vendedor.nombre}}
              {{vendedor.apellido_paterno?vendedor.apellido_paterno:''}}
              {{vendedor.apellido_materno?vendedor.apellido_materno:''}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-3">
        <div class="input-group">
          <span class="input-group-text">
            Agrupado por
          </span>
          <select class="form-select" v-model="agrupado_por" @change="refrescarFichas()">
            <option value="sin_agrupar">Sin agrupar</option>
            <option value="tipos">Tipos</option>
            <option value="vendedores">Vendedores</option>
          </select>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="input-group">
          <span class="input-group-text">
            Tipo de ficha
          </span>
          <select class="form-select" v-model="id_tipo" @change="refrescarFichas()">
            <option :value="null">Todos</option>
            <option v-for="tipo in tipos" :key="tipo.id" :value="tipo.id">{{tipo.descripcion}}</option>
          </select>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-3 col-md-6 mb-3">
        <div class="input-group">
          <span class="input-group-text">
            Tipo de autenticación
          </span>
          <select class="form-select" v-model="tipo_autenticacion" @change="refrescarFichas()">
            <option :value="null">Todos</option>
            <option :value="1">PIN</option>
            <option :value="2">Usuario y contraseña</option>
          </select>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-3">
        <div class="input-group">
          <span class="input-group-text">
            Estado
          </span>
          <select class="form-select" v-model="estado" @change="refrescarFichas()">
            <option :value="null">Todas</option>
            <option value="1">Nuevas</option>
            <option value="2">En uso</option>
            <option value="3">En pausa</option>
            <option value="4">Finalizadas</option>
            <option value="5">Vendidas</option>
          </select>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 p-2 mb-3">
        <div class="form-check">
          <input
            class="form-check-input form-check-input-success"
            type="checkbox" name="fechas" id="fechas"
            v-model="por_fecha" @change="refrescarFichas()"
          />
          <label class="form-check-label" :class="{ 'text-success': por_fecha }" for="fechas">
            <strong>Filtrar por fecha</strong>
          </label>
        </div>
      </div>
    </div>
    <div class="row" v-show="por_fecha">
      <div class="offset-lg-3 col-lg-3 col-md-6">
        <label>Fecha inicial</label>
        <Datepicker
          ref="fecha_inicial"
          v-model="fecha_inicial"
          placeholder="dd/mm/aaaa"
          :class="'form-control bg-white'"
          :inputFormat="'dd/MM/yyyy'"
        />
      </div>
      <div class="col-lg-3 col-md-6">
        <label for="">Fecha Final</label>
        <Datepicker
          ref="fecha_final"
          v-model="fecha_final"
          placeholder="dd/mm/aaaa"
          :class="'form-control bg-white'"
          :inputFormat="'dd/MM/yyyy'"
        />
      </div>
      <div class="col-lg-3 col-md-6 pt-4">
        <button class="btn btn-secondary"
          @click="refrescarFichas()"
        >
          <i class="mdi mdi-refresh"></i>
        </button>
      </div>
    </div>
    <div class="row" style="padding-top: 10px">
      <div class="col-md-9">
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="precio"
            v-model="columnas.precio"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="precio">
            Precio
          </label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="estado"
            v-model="columnas.estado"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="estado">
            Estado
          </label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="fechaCreacion"
            v-model="columnas.fecha_creacion"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="fechaCreacion">
            Fecha de creación
          </label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="fechaVenta"
            v-model="columnas.fecha_venta"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="fechaVenta">
            Fecha de venta
          </label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="tiempoRestante"
            v-model="columnas.tiempo_restante"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="tiempoRestante">
            Tiempo restante
          </label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="fechaExpiracion"
            v-model="columnas.fecha_expiracion"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="fechaExpiracion">
            Fecha de expiración
          </label>
        </div>
      </div>

      <div class="col-md-3 text-right">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{tf}} fichas en total
        </span>
      </div>
    </div>
    <br>
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Listado fichas
          <small class="font-size-10">
            Aquí puedes ver las fichas registradas
          </small>
        </h4>
      </div>
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive" style="min-height: 475px;">
          <table class="tblRouters table table-hover table-nowrap" id="tabla-fichas">
            <thead>
              <tr>
                <th style="width: 30px" v-if="agrupado_por == 'sin_agrupar'">
                  <input
                    type="checkbox"
                    v-model="seleccionar_todo"
                    @change="seleccionarFichas()"
                    :title="!seleccionar_todo?'Seleccionar todas las fichas':'Deseleccionar todas las fichas'"
                  />
                </th>
                <th v-if="agrupado_por == 'sin_agrupar'">ID</th>
                <th v-if="agrupado_por == 'sin_agrupar'">Tipo de autenticación</th>
                <th v-if="agrupado_por == 'sin_agrupar'">PIN / Usuario</th>
                <th v-if="agrupado_por == 'sin_agrupar' || agrupado_por == 'tipos'" style="width: 200px">Tipo de ficha</th>
                <th v-if="agrupado_por == 'sin_agrupar' || agrupado_por == 'vendedores'" style="width: 200px">Vendedor</th>
                <th v-if="agrupado_por != 'sin_agrupar'">Cantidad</th>
                <th v-show="columnas.precio">Precio</th>
                <th v-if="agrupado_por != 'sin_agrupar'">Monto total Fichas</th>
                <th v-if="agrupado_por == 'sin_agrupar'" v-show="columnas.estado">Estado</th>
                <th v-if="agrupado_por != 'sin_agrupar'">Total Vendidas</th>
                <th v-if="agrupado_por == 'sin_agrupar'" v-show="columnas.fecha_creacion" style="width: 150px">Fecha de creación</th>
                <th v-if="agrupado_por == 'sin_agrupar'" v-show="columnas.fecha_venta" style="width: 150px">Fecha de venta</th>
                <th v-if="agrupado_por == 'sin_agrupar'" v-show="columnas.tiempo_restante" style="width: 150px">Tiempo restante</th>
                <th v-if="agrupado_por == 'sin_agrupar'" v-show="columnas.fecha_expiracion" style="width: 170px">Fecha de expiración</th>
                <th style="width: 50px" class="text-center">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ficha in fichas" :key="ficha.id">
                <td v-if="agrupado_por == 'sin_agrupar'">
                  <input
                    type="checkbox"
                    v-model="ficha.selecionar"
                    ref="seleccionarFicha"
                    @change="fichaSelec = !fichaSelec"
                    :disabled="ficha.mt_venta != null"
                    :title="!ficha.selecionar?'Seleccionar ficha':'Deseleccionar ficha'"
                  />
                </td>
                <td v-if="agrupado_por == 'sin_agrupar'" class="fw-semibold">{{ ficha.id}}</td>
                <td v-if="agrupado_por == 'sin_agrupar'">
                  <span :class="ficha.clave != null ? 'text-success' : 'text-warning'">
                    <strong>
                      {{ficha.clave != null ? 'Usuario y contraseña' : 'PIN'}}
                    </strong>
                  </span>
                </td>
                <td v-if="agrupado_por == 'sin_agrupar'" class="fw-semibold">
                  {{ ficha.pin}}
                </td>
                <td v-if="agrupado_por == 'sin_agrupar' || agrupado_por == 'tipos'">
                  {{ ficha.descripcion_tipo }}
                </td>
                <td v-if="agrupado_por == 'sin_agrupar' || agrupado_por == 'vendedores'" class="fw-semibold">
                  {{ ficha.nombre_vendedor }}
                </td>
                <td v-if="agrupado_por != 'sin_agrupar'">
                  {{ ficha.cantidad_fichas }}
                </td>
                <td v-show="columnas.precio">
                  <span class="badge bg-soft-dark font-size-12">
                    {{ formatoMoneda(ficha.precio) }}
                  </span>
                </td>
                <td v-if="agrupado_por != 'sin_agrupar'">
                  <span class="badge bg-soft-dark font-size-12">
                    {{ formatoMoneda(ficha.monto_total) }}
                  </span>
                </td>
                <td v-if="agrupado_por == 'sin_agrupar'" v-show="columnas.estado">
                  <span
                    class="badge bg-soft-dark font-size-12 bg-gradient"
                    v-if="agrupado_por == 'sin_agrupar' && ficha.mt_venta == null"
                  >
                    Nueva
                  </span>
                  <span class="badge bg-success bg-gradient font-size-12"
                    v-if="agrupado_por == 'sin_agrupar' && ficha.mt_venta != null &&
                    ficha.vencida == false && ficha.mt_login != null && ficha.mt_logout == null"
                  >
                    En uso
                  </span>
                  <span class="badge bg-warning bg-gradient font-size-12"
                    v-if="agrupado_por == 'sin_agrupar' && ficha.mt_venta != null &&
                    ficha.vencida == false && ficha.mt_login != null && ficha.mt_logout != null"
                  >
                    Pausada 
                  </span>
                  <span class="badge bg-primary bg-gradient font-size-12"
                    v-if="agrupado_por == 'sin_agrupar' && ficha.vencida == true"
                  >
                    Finalizada 
                  </span>
                </td>
                <td v-if="agrupado_por != 'sin_agrupar'">
                  <span class="text-dark">
                    {{ficha.cantidad_fichas_vendidas}}
                  </span>
                </td>
                <td v-if="agrupado_por == 'sin_agrupar'" v-show="columnas.fecha_creacion">
                  {{ formatoFecha(ficha.created_at) }}
                </td>
                <td v-if="agrupado_por == 'sin_agrupar'" v-show="columnas.fecha_venta">
                  {{ ficha.mt_venta ? formatoFecha(ficha.mt_venta) : '' }}
                </td>
                <td v-if="agrupado_por == 'sin_agrupar'" v-show="columnas.tiempo_restante">
                  {{ ficha.tiempo == 0 ? 'No aplica' : tiempoRestanteFicha(ficha) }}
                </td>
                <td v-if="agrupado_por == 'sin_agrupar'" v-show="columnas.fecha_expiracion">
                  {{ ficha.mt_max_validez != null ? formatoFecha(ficha.mt_max_validez) : 'No aplica' }}
                </td>
                <td class="text-center">
                  <div class="dropdown">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-horizontal-rounded"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li v-if="agrupado_por != 'sin_agrupar'">
                        <router-link
                          v-if="agrupado_por == 'tipos' && ficha.id_tipo != undefined"
                          class="dropdown-item warning"
                          :to="{
                            name: 'fichasPorTiposHotspot',
                            params:{
                              id: ficha.id_tipo,
                              por_fecha: por_fecha ? 1 : 0,
                              tipo_autenticacion: tipo_autenticacion,
                              fecha_inicial: formatoFechaFicha(fecha_inicial),
                              fecha_final: formatoFechaFicha(fecha_final)
                            }
                          }"
                        >
                          <i class="mdi mdi-eye text-warning"></i>
                          Detalles
                        </router-link>
                        <router-link
                          v-if="agrupado_por == 'vendedores' && ficha.id_vendedor != undefined"
                          class="dropdown-item warning"
                          :to="{
                            name: 'fichasPorVendedoresHotspot',
                            params:{
                              id: ficha.id_vendedor,
                              por_fecha: por_fecha ? 1 : 0,
                              tipo_autenticacion: tipo_autenticacion,
                              fecha_inicial: formatoFechaFicha(fecha_inicial),
                              fecha_final: formatoFechaFicha(fecha_final)
                            }
                          }"
                        >
                          <i class="mdi mdi-eye text-warning"></i>
                          Detalles
                        </router-link>
                      </li>
                      <li v-if="agrupado_por == 'sin_agrupar'">
                        <button
                          class="dropdown-item warning"
                          @click="detallesFicha(ficha.id)"
                          v-if="agrupado_por == 'sin_agrupar'"
                        >
                          <i class="mdi mdi-eye text-warning"></i>
                          Detalle
                        </button>
                      </li>
                      <li v-if="agrupado_por == 'sin_agrupar'">
                        <button
                          class="dropdown-item primary"
                          @click="finalizarFicha(ficha.id)"
                          v-if="agrupado_por == 'sin_agrupar'"
                          :disabled="ficha.mt_inicio == null || ficha.vencida == 1"
                        >
                          <i class="fa fa-ban text-primary"></i>
                          Finalizar
                        </button>
                      </li>
                      <li v-if="agrupado_por == 'sin_agrupar'">
                        <button
                          class="dropdown-item info"
                          @click="imprimirFicha(ficha)"
                          v-if="agrupado_por == 'sin_agrupar'"
                          :disabled="ficha.mt_venta != null"
                        >
                          <i class="mdi mdi-printer text-info"></i>
                          Imprimir
                        </button>
                      </li>
                      <li v-if="agrupado_por != 'sin_agrupar'">
                        <button
                          class="dropdown-item danger"
                          @click="agrupado_por == 'tipos' ? eliminarFichasTipo(ficha):eliminarFichasVendedor(ficha)"
                          v-if="agrupado_por != 'sin_agrupar'"
                        >
                          <i class="mdi mdi-trash-can-outline text-danger"></i>
                          Eliminar
                        </button>
                      </li>
                      <li v-if="agrupado_por == 'sin_agrupar'">
                        <button
                          class="dropdown-item danger"
                          @click="eliminarFicha(ficha.id)"
                          v-if="agrupado_por == 'sin_agrupar'"
                          :disabled="ficha.mt_venta != null"
                        >
                          <i class="mdi mdi-trash-can-outline text-danger"></i>
                          Eliminar
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-lg-12" v-show="agrupado_por =='sin_agrupar' && mostrarEliminarFichasBLoque > 0">
            <button
              class="btn btn-outline-info btn-sm"
              @click="imprimirFichas()"
              :title="mostrarEliminarFichasBLoque == 1 ? 'Imprimir ficha selecionada' : 'Imprimir fichas seleccionadas'"
            >
              <i class="mdi mdi-printer"></i>
              Imprimir
            </button>
            <button
              @click="eliminarBLoqueFichas()"
              class="btn btn-outline-danger btn-sm"
              :title="mostrarEliminarFichasBLoque == 1 ? 'Eliminar ficha selecionada' : 'Eliminar fichas seleccionadas'"
            >
              <i class="mdi mdi-trash-can-outline"></i>
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-center">
        <paginador
          :pag="page"
          :totpag="totpag"
          :epp="epp"
          @cargar-pagina="cargarPagina"
          @epp-actualizado="
            epp = $event,
            refrescarFichas()
          "
        ></paginador>
      </div>
    </div>
    <mdl-ficha-detalles
      ref="mdlDetalles"
      v-on:ficha:actualizada="actualizarDatosFichaEnLista($event)"
    ></mdl-ficha-detalles>
    <br>
  </Layout>
</template>

<script src="./ListadoFichas.js"></script>

<style scoped>
.form-check-input-success:checked {
  background-color: #63AD6F;
  color: #63AD6F;
  border-color: #63AD6F;
}
</style>