/**
 * ListadoFichas.js
 */
import { APIHOTS as API } from '@/API.js'
import CmpSecciones from './CmpSecciones.vue'
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import accounting from 'accounting/accounting.js'
import HotspotsSrv from '@/services/hotspot/HotspotsSrv.js'
import FichasSrv from '@/services/hotspot/FichasSrv.js'
import PuntoVentaSrv from '@/services/hotspot/PuntoVentaSrv.js'
import vendedorSrv from '@/services/hotspot/VendedorSrv.js'
import FichaTipoSrv from '@/services/hotspot/FichaTipoSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import MdlFichaDetalles from './MdlFichaDetalles.vue'
import Datepicker from 'vue3-datepicker'
import moment from 'moment'
import { todoGetters } from "@/state/helpers";
import Layout from '@/views/layouts/main'
import Swal from 'sweetalert2'

export default {
  name: 'ListadoFichas',
  components : {
    Layout,
    CmpSecciones,
    Filtrador,
    Paginador,
    MdlFichaDetalles,
    Datepicker
  },
  data () {
    return {
      idInterval: null,
      dato: '',
      fechaYHora: null,
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoFichas.por_pagina') || 10),
      tf: 0, // Total de fichas
      totpag: 0, // Total de páginas
      token: localStorage.getItem('argusblack.token'),
      seleccionar_todo: false,
      fichaSelec: false,

      fichas:[],
      puntos_ventas: [],
      vendedores: [],
      tipos: [],
      hotspots: [],
      
      id_hotspot: null,
      id_tipo: null,
      id_vendedor: null,
      id_punto_venta: null,
      agrupado_por: 'sin_agrupar',
      fecha_inicial: null,
      fecha_final: null,
      por_fecha: false,
      tipo_autenticacion: null,
      estado: null,





      columnas: {
        precio: true,
        estado: true,
        fecha_creacion: true,
        fecha_venta: true,
        tiempo_restante: true,
        fecha_expiracion: true
      }
    }
  },
  computed: {
    ...todoGetters,
    usuario: function() {
      var self = this
      return self.$store.state.todo.usuario
    },
    mostrarEliminarFichasBLoque () {
      var self = this, fichas = []

      if(self.seleccionar_todo) fichas = self.fichas.filter(ficha => ficha.selecionar == true)
      
      if(self.fichaSelec) fichas = self.fichas.filter(ficha => ficha.selecionar == true)
      else fichas = self.fichas.filter(ficha => ficha.selecionar == true)

      return fichas.length
    },
    usuarioActEsAdmin() {
      return this.usuarioActualEsAdministrador()
    },
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  watch: {
    uc (estaCargado) {
      let self = this

      // Si el usuario no está cargado se evita continuar
      if(!estaCargado) return 

      if (self.usuarioActEsAdmin) 
        self.cargarVendedores()
      else 
        self.id_vendedor = self.usuario.id
    },
    fecha_inicial : function(newVal, oldVal) {
      var self = this 
      self.refrescarFichas()
    },
    fecha_final : function(newVal, oldVal) {
      var self = this 
      self.refrescarFichas()
    }
  },
  created : function () {
    var self = this

    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp)  self.epp  = parseInt(self.$route.query.epp)
    if (self.$route.query.id_hotspot) self.id_hotspot = parseInt(self.$route.query.id_hotspot)
    if (self.$route.query.agrupado_por) self.agrupado_por = self.$route.query.agrupado_por
    if (self.$route.query.id_tipo) self.id_tipo = parseInt(self.$route.query.id_tipo)
    //if (self.$route.query.id_punto_venta) self.id_punto_venta = parseInt(self.$route.query.id_punto_venta)
    if (self.$route.query.fecha_inicial) self.fecha_inicial = new Date(moment(self.$route.query.fecha_inicial, 'YYYY-MM-DD').format())
    if (self.$route.query.fecha_final) self.fecha_final = new Date(moment(self.$route.query.fecha_final, 'YYYY-MM-DD').format())
    if (self.$route.query.por_fecha) self.por_fecha = self.$route.query.por_fecha == 'true' ? true:false
    if (self.$route.query.tipo_autenticacion) self.tipo_autenticacion = self.$route.query.tipo_autenticacion
    if (self.$route.query.estado) self.estado = self.$route.query.estado

    // Si el usuario está cargado y es administrador
    if (self.uc) {
      if (self.usuarioActEsAdmin) {
        if (self.$route.query.id_vendedor) self.id_vendedor = parseInt(self.$router.query.id_vendedor)

        self.cargarVendedores()
      }
      else
        self.id_vendedor = self.usuario.id
    }

    //self.cargarPuntoVenta()
    self.cargarTiposFichas()
    self.cargarHotspots()
    // self.cargarVendedores()

    // Carga de la fecha y hora
    let promFYH = SistemaSrv.fechaYHora()
    promFYH.then(response => {
      self.fechaYHora = response.data
    })

    // Carga de la fecha del sistema
    if (self.fecha_inicial == null || self.fecha_final == null) {
      SistemaSrv.fecha({formato: 'Y-m-d'}).then(response => {
        self.fecha_inicial = new Date(moment(response.data).format())
        self.fecha_final = new Date(moment(response.data).format())
        // Carga inicial de los abonos
        promFYH.then(response => {
          self.refrescarFichas()
        })
      })
    } else { 
      promFYH.then(response => {
        self.refrescarFichas()
      })
    }


    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()
  },
  methods: {
    actualizarDatosFichaEnLista(fichaActualizada) {
      let self = this
      
      let ficha = self.fichas.find(function(f){
        return f.id == fichaActualizada.id
      })

      if ( ficha == undefined ) return

      SistemaSrv.fechaYHora().then(response => {
        self.fechaYHora = response.data
        Object.assign(ficha, fichaActualizada)
      })
    },

    actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.listadoFichas.columnas', JSON.stringify(self.columnas))
    },

    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem('argusblack.listadoFichas.columnas')

      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },

    calcularTiempoFecha: function (mt_inicio, tiempo) {
      var self = this

      if(mt_inicio == null ){
        return self.convertidorSegundos_a_DHM(tiempo)
      }

      var fechaInicio = new Date(mt_inicio).getTime()
      var fechaActual = new Date().getTime()
      var fecha = ((fechaInicio / 1000) + tiempo) - (fechaActual / 1000)
      
      return self.convertidorSegundos_a_DHM(fecha)
    },

    convertidorSegundos_a_DHM(segundos) {
      let dhm = ''

      if(segundos < 0 ) segundos = 0;

      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)

      if ( dias > 0 ) dhm += dias + ' dia' + (dias == 0 || dias > 1 ? 's': '')
      if ( horas > 0 && dhm != '' ) dhm += ', '
      if ( horas > 0 ) dhm += horas + ' hora' + (horas == 0 || horas > 1 ? 's': '')
      if ( minutos > 0 && dhm != '') dhm += ', '
      if ( minutos > 0 ) dhm += minutos + ' minuto' + (minutos == 0 || minutos > 1 ? 's' : '') 

      if ( dhm == '' ) dhm = '0 minutos'

      return dhm
    },


    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarFichas({ page: n })
    },

    cargarPuntoVenta: function () {
      var self = this

      PuntoVentaSrv.puntosVentasJSON().then(response => {
        self.puntos_ventas = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los puntos de ventas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarTiposFichas(){
      var self = this
      
      let params = {
        con_eliminadas: true
      }

      FichaTipoSrv.fichasTiposJSON(params).then(response => {
        self.tipos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los tipos de fichas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarHotspots: function(){
      var self = this

      let params = {
        con_eliminados: true
      }

      HotspotsSrv.hotspotsJSON(params).then(response => {
        self.hotspots = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se cargaron los hotspots'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarVendedores: function() {
      var self = this

      let params = {
        con_eliminados: true
      }

      vendedorSrv.vendedoresJSON(params).then(response => {
        self.vendedores = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se cargaron los vendedores'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    completarFichas: function(fichas) {
      var self = this

      fichas.forEach(ficha => {
        var tipo = self.tipos.filter(tipo => tipo.id == ficha.id_tipo)
        var vendedor = self.vendedores.filter(vendedor => vendedor.id == ficha.id_vendedor)

        var descripcion = '', nombre = ''

        if(tipo.length > 0) descripcion = tipo[0].descripcion
        else descripcion = 'No se encuentra un tipo de ficha'

        if(vendedor.length > 0) nombre = vendedor[0].nombre+' '+(vendedor[0].apellido_paterno?vendedor[0].apellido_paterno:'')+' '+(vendedor[0].apellido_materno?vendedor[0].apellido_materno:'')
        else nombre = 'Sin vendedor asignado'

        ficha.descripcion_tipo= descripcion
        ficha.nombre_vendedor = nombre
        ficha.selecionar = false
      })
    },

    detallesFicha: function(idFicha) {
      this.$refs.mdlDetalles.mostrar(idFicha)
    },

    eliminarFicha: function(idFicha) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Eliminar la ficha?",
        html: "¿Está seguro de eliminar el ficha con el ID <br><b>"+idFicha+"</b>?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FichasSrv.eliminarFICHA(idFicha).then(response => {
            swal.fire("Eliminado!", "ficha con el ID "+idFicha+" eliminado.", "success");
            self.refrescarFichas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se pudo eliminar la ficha con el ID "+idFicha
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    eliminarFichasTipo: function(ficha) {
      var self = this

      var params = {
        id_tipo: ficha.id_tipo
      }

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title:  "¿Eliminar las fichas relacionadas con el tipo?",
        html: '¿Está seguro de eliminar las fichas realacionados con el tipo <br><b>'+ficha.descripcion_tipo+'</b>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FichasSrv.eliminarFICHAS(params).then(response => {
            swal.fire("Eliminado!", '¡Las fichas realacionadas con el tipo "'+ficha.descripcion_tipo+'" se han eliminado correctamente!', "success");
            self.refrescarFichas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = '¡Las fichas realacionadas con el tipo "'+ficha.descripcion_tipo+'" no se han eliminado correctamente!'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    eliminarFichasVendedor: function(ficha) {
      var self = this

      var params = {
        id_vendedor: ficha.id_vendedor
      }

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Eliminar las fichas relacionadas con el vendedor?",
        html: '¿Está seguro de eliminar las fichas realacionados con el vendedor <br><b>'+ficha.nombre_vendedor+'</b>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FichasSrv.eliminarFICHAS(params).then(response => {
            swal.fire("Eliminado!", '¡Las fichas realacionadas con el vendedor "'+ficha.nombre_vendedor+'" se han eliminado correctamente!', "success")
            self.refrescarFichas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = '¡Las fichas realacionadas con el vendedor "'+ficha.nombre_vendedor+'" no se han eliminado correctamente!'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    eliminarBLoqueFichas: function() {
      var self = this, idsFichas=[]

      var fichasSelecionadas = self.fichas.filter(ficha => ficha.selecionar == true)

      if(fichasSelecionadas.length == 0) {
        iu.msg.warning('No se han seleccionado fichas para eliminar')
        return
      }

      fichasSelecionadas.forEach(fichaSec => {
        idsFichas.push(fichaSec.id)
      })

      var params = {
        ids_fichas: idsFichas
      }

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Eliminar las fichas selecionadas?",
        html: "¿Está seguro de eliminar las fichas seleccionadas?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FichasSrv.eliminarFICHAS(params).then(response => {
            swal.fire("Eliminado!", "¡Las fichas seleccionadas se han eliminado correctamente!.", "success")
            self.refrescarFichas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se han podido eliminar las fichas seleccionadas"
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    finalizarFicha: function(idFicha) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Finalizar ficha?",
        html: "¿Está seguro de finalizar la ficha con el ID <br><b>"+idFicha+"</b>?",
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FichasSrv.finalizarFICHA(idFicha).then(response => {
            swal.fire("Finalizado!", "ficha con el ID "+idFicha+" finalizado.", "success")
            self.refrescarFichas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se pudo finalizar la ficha con el ID "+idFicha
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },

    formatoFechaFicha(mt){
      return moment(mt, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },

    imprimirFichas(){
      var self = this, idsFichas = []

      if(!self.tienePermiso('imprimirFichasHotspot','vista')) {
        iu.msg.warning(
          `No tienes permiso para acceder a <strong style="color: #e65853">imprimirFichasHotspot</strong>,
          revisa los permisos de rol con tu administrador de sistema`)
        setTimeout(() => {
          self.$router.replace({
            name: 'inicio'
          })
        }, 50)
        return
      }

      var fichasSelecionadas = self.fichas.filter(ficha => ficha.selecionar == true)

      if(fichasSelecionadas.length == 0) {
        iu.msg.warning('No se han seleccionado fichas para eliminar')
        return
      }

      fichasSelecionadas.forEach(fichaSec => {
        idsFichas.push(fichaSec.id)
      })

      let rutaDestino = this.$router.resolve({name: 'imprimirFichasHotspot', query: { ids_fichas: idsFichas }});
      window.open(rutaDestino.href, '_blank');
    },

    imprimirFicha: function(ficha){
      var self = this, idsFichas = []

      if(!self.tienePermiso('imprimirFichasHotspot','vista')) {
        iu.msg.warning(
          `No tienes permiso para acceder a <strong style="color: #e65853">imprimirFichasHotspot</strong>,
          revisa los permisos de rol con tu administrador de sistema`)
        setTimeout(() => {
          self.$router.replace({
            name: 'inicio'
          })
        }, 50)
        return
      }

      idsFichas.push(ficha.id)

      let rutaDestino = this.$router.resolve({name: 'imprimirFichasHotspot', query: { ids_fichas: idsFichas }});
      window.open(rutaDestino.href, '_blank');
    },

    refrescarFechaYHora() {
      let self = this
      SistemaSrv.fechaYHora().then(response => {
        self.fechaYHora = response.data
      })
    },

    refrescarFichas (){
      var self = this

      iu.spinner.mostrar('#tabla-fichas')
      
      self.fichas = []

      var query = {
        dato: self.dato,
        page: self.page,
        epp: self.epp,
        id_hotspot: self.id_hotspot,
        agrupado_por: self.agrupado_por,
        tipo_ficha: self.tipo_ficha,
        id_vendedor: self.id_vendedor,
        por_fecha: self.por_fecha,
        tipo_autenticacion: self.tipo_autenticacion,
        estado: self.estado,
        //id_punto_venta: self.id_punto_venta
      }

      if(self.por_fecha){
        Object.assign(query,{
          fecha_inicial : self.formatoFechaFicha(self.fecha_inicial),
          fecha_final : self.formatoFechaFicha(self.fecha_final)
        })
      }

      self.$router.replace({
        query: query
      }).catch(error => {})

      let params = {
        dato: self.dato,
        page: self.page,
        epp: self.epp,
        id_hotspot: self.id_hotspot,
        id_tipo: self.id_tipo,
        id_vendedor: self.id_vendedor,
        tipo_autenticacion: self.tipo_autenticacion,
        estado: self.estado,
        //id_punto_venta: self.id_punto_venta
      }

      if(self.agrupado_por != 'sin_agrupar'){
        Object.assign(params,{
          agrupado_por: self.agrupado_por
        })
      }

      if(self.por_fecha){
        Object.assign(params,{
          fecha_inicial : self.formatoFechaFicha(self.fecha_inicial),
          fecha_final : self.formatoFechaFicha(self.fecha_final)
        })
      }

      FichasSrv.fichasJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoFichas.por_pagina',self.epp)
        let page = response.data
        self.fichas = page.data
        self.tf = page.total
        self.totpag = page.last_page

        if(self.agrupado_por == 'sin_agrupar') self.completarFichas(self.fichas)

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarFichas()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las fichas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tabla-fichas')
      })
    },

    seleccionarFichas: function() {
      var self = this

      self.fichas.forEach(ficha => {
        if(self.seleccionar_todo && ficha.mt_venta == null) ficha.selecionar = true
        else ficha.selecionar = false
      })
    },

    tiempoRestanteFicha(ficha) {
      let self = this
      let segundosUso = ficha.t_uso 
      let dtInicial = null
      let dtFinal = null

      if ( ficha.mt_inicio == null ) segundosUso = 0 
      else if ( ficha.vencida == false && ficha.mt_login != null && ficha.mt_logout == null) {
        dtInicial = new Date(ficha.mt_login.replace(' ', 'T'))
        dtFinal = new Date(self.fechaYHora.replace(' ', 'T'))

        segundosUso += ( (dtFinal.getTime() - dtInicial.getTime()) / 1000 )
      }

      if ( segundosUso % 60 > 0 ) segundosUso = segundosUso - (segundosUso % 60) + 60

      let segundosRestantes = ficha.tiempo - segundosUso
      return self.convertidorSegundos_a_DHM(segundosRestantes) 
    }
  },

  mounted() {
    let self = this

    iu.spinner.mostrar('#tabla-fichas')

    this.idInterval = setInterval(function(){
      self.refrescarFichas()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.idInterval)
  }
}