import axios from 'axios'
import { APIHOTS as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/puntos-venta`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(venta) {
    return apiClient.patch(`/${venta.id}`, venta)
  },

  eliminar(idVenta) {
    return apiClient.delete(`/${idVenta}`)
  },

  guardar(venta) {
    return apiClient.post('', venta)
  },

  puntosVentasJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/puntos-venta.json`, { params: params })
  },

  puntoVentaJSON(idVenta,params) {
    params = params || {}
    return apiClient.get(`${idVenta}.json`,{params:params})
  }
}